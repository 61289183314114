// src/App.js

import React from 'react';

const ProjectList = () => {
    return (
        <div className="ProjectList">
            <h1>Hello, React!</h1>
            <p>This is a basic React web page.</p>
        </div>
    );
}

export default ProjectList;
