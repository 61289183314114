import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


export const currencyFormat = (num) => {
    if (num > 0) {
        const formatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 7 });
        return formatter.format(num);
    }
    return num;
}


export const isNotEmpty = (value) => {
    return typeof value === 'string' && value.trim().length > 0;
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4b5363',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const subtotal = (items) => {
    return items.reduce((total, item) => total + parseFloat(item.amount) || 0, 0);
}

export const balance = (amount, items) => {
    return amount - subtotal(items);
}

export const getRoundOffValue = (value) => {
    return Math.round(value)
}

export const getTowDecimalValue = (value) => {
    if (value === null || value === undefined) {
        return '';
    }
    return value.toFixed(2)
}