// Example using React Context API
import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [, setIsPaymentDetailAccessible] = useState(false);

    const [user, setUser] = useState(null);


    const login = (user) => {
        // Implement your login logic
        setIsLoggedIn(true);
        setUser(user);
        (user.is_payment_accessible) ? setIsPaymentDetailAccessible(true) : setIsPaymentDetailAccessible(false);

    };

    const logout = () => {
        // Implement your logout logic
        setIsLoggedIn(false);
        setUser(null);
    };


    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout, user }}>
            {children}
        </AuthContext.Provider>
    );
};

