import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthProvider';
import ProtectedRoute from './ProtectedRoute';
import './App.css';
import Login from './components/Login';
import ProjectList from './components/ProjectList';
import Summary from './components/Summary'
import Header from './components/Header'
import ProjectSummary from './components/ProjectSummary';
import StickyHeader from './components/StickyHeader';
import SpanningTable from './components/SpanningTable';

function App() {
  return (
    <AuthProvider>
      <Header />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/projectlist" element={<ProtectedRoute><ProjectList /></ProtectedRoute>} />
          <Route path="/account" element={<ProtectedRoute><Summary /></ProtectedRoute>} />
          <Route path="/projectsummary" element={<ProtectedRoute><ProjectSummary /></ProtectedRoute>} />
          <Route path="/stickyheader" element={<ProtectedRoute><StickyHeader /></ProtectedRoute>} />
          <Route path="/spanningtable" element={<ProtectedRoute><SpanningTable /></ProtectedRoute>} />


          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </AuthProvider>

  );
}

export default App;
