// Header.js

import React from 'react';
import { useAuth } from '../AuthProvider';


const Header = () => {
    const { isLoggedIn, logout, user } = useAuth();


    return (
        <header>
            <h1 style={{ textAlign: 'center' }}>Pavitram Construction</h1>
            {isLoggedIn && <div className="user-info">User: {user.name}</div>}
            {isLoggedIn && <button className="logout-button" onClick={logout}>Logout</button>}
        </header>
    );
}

export default Header;
