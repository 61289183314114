import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import '../App.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from 'react-modal';
import { currencyFormat, StyledTableRow, StyledTableCell } from './Comman';

Modal.setAppElement('#root');

function subtotal(items) {
    return items.reduce((total, item) => total + parseFloat(item.amount) || 0, 0);
}

function balance(total, items) {
    return total - subtotal(items);
}

const OverallPaymentsPopup = ({ isVisible, onClose, total, project_access }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [overallPayments, setOverallPayments] = useState([]);

    useEffect(() => {
        const fetchProjectPayments = async () => {
            setIsLoading(true);
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + `/api/expensemanager/overall_payments`, { project_access });
                await setOverallPayments(response.data);
            } catch (error) {
                console.error('Error fetching category data:', error);
                setError('Error fetching expense report data!')
            }
        };
        fetchProjectPayments();
        setIsLoading(false)
    }, [isVisible]);

    if (!isVisible) return null;

    // Render loading state, error, or data
    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return ReactDOM.createPortal(
        <div className="popup">
            <div className="popup-content">
                <span className="close" onClick={onClose}>&times;</span>
                <header className="header">
                    <h2>Overall Payments</h2>
                    &nbsp;&nbsp;
                    <div className="text_total">Total: {currencyFormat(total)}</div>
                    &nbsp;&nbsp;
                    <div className="text_payment">Payments: {currencyFormat(subtotal(overallPayments))}</div>
                    &nbsp;&nbsp;
                    <div className="text_balance">Balance: {currencyFormat(balance(total, overallPayments))}</div>
                </header>
                <div className="scrollable-content">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 600 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">#</StyledTableCell>
                                    <StyledTableCell align="left">Date</StyledTableCell>
                                    <StyledTableCell align="left">Project</StyledTableCell>
                                    <StyledTableCell align="left">Payment Mode</StyledTableCell>
                                    <StyledTableCell align="right">Amount</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {overallPayments.map((overallPayment, index) => (
                                    <StyledTableRow key={overallPayment.seq}>
                                        <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                        <StyledTableCell align="left">{overallPayment.expensed_date}</StyledTableCell>
                                        <StyledTableCell align="left">{overallPayment.account}</StyledTableCell>
                                        <StyledTableCell align="left">{overallPayment.payment_method}</StyledTableCell>
                                        <StyledTableCell align="right">{currencyFormat(overallPayment.amount)}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>,
        document.body // Append to body
    );
};

export default OverallPaymentsPopup;
