// Summary.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { currencyFormat, StyledTableRow, StyledTableCell } from './Comman';
import PaymentsDetailPopup from './PaymentsDetailPopup'
import OverallPaymentsPopup from './OverallPaymentsPopup';

function subtotal(items) {
    return items.reduce((total, item) => total + parseFloat(item.amount) || 0, 0);
}
function balance(total, items) {
    return subtotal(total) - subtotal(items);
}
const Summary = () => {
    const navigate = useNavigate();
    const [reports, setReports] = useState([]);
    const { logout, user } = useAuth();
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedProjectTotalAmount, setSelectedProjectTotalAmount] = useState('');

    const [isPaymentPopupVisible, setIsPaymentPopupVisible] = useState(false);
    const [isOverallPaymentPopupVisible, setIsOverallPaymentPopupVisible] = useState(false);

    const hidePaymentPopup = () => setIsPaymentPopupVisible(false);
    const hideOverallPaymentPopup = () => setIsOverallPaymentPopupVisible(false);
    const [overallPayments, setOverallPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const project_access = user.project_access;
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + `/api/expensemanager/summary_report`, { project_access });
                setReports(response.data);
            } catch (error) {
                console.error('Error fetching data-1:', error);
            }
        };

        fetchData();

        const fetchOverallPaymentData = async () => {
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + `/api/expensemanager/overall_payments`, { project_access });
                setOverallPayments(response.data);
            } catch (error) {
                console.error('Error fetching data-1:', error);
            }
        };

        fetchOverallPaymentData();
        //setIsLoading(false)
    }, []);

    const handleOnClickView = (account_val) => {
        navigate('/projectsummary', { state: { account: account_val } })
    }

    const showPaymentsDetailPopup = (project_val, project_total_val) => {
        setSelectedProject(project_val);
        setSelectedProjectTotalAmount(project_total_val);
        setIsPaymentPopupVisible(true);
    }

    const showOverallPaymentsPopup = () => {
        setIsOverallPaymentPopupVisible(true);
    }


    return (
        <div>
            <header className="header">
                <h1 className="heading">Summary</h1>
                <Button variant="outlined" size="small" onClick={logout}>Back</Button>
                {(user.is_payment_accessible !== 'false') && (
                    <Button variant="outlined" size="small" onClick={() => showOverallPaymentsPopup()}>Overall Payments</Button>
                )}
                <OverallPaymentsPopup
                    isVisible={isOverallPaymentPopupVisible}
                    onClose={hideOverallPaymentPopup}
                    total={subtotal(reports)}
                    project_access={user.project_access} />

                &nbsp;&nbsp;
                <div className="text_total">Total: {currencyFormat(subtotal(reports))}</div>
                &nbsp;&nbsp;
                {(user.is_payment_accessible !== 'false') && (
                    <div className="text_payment">Payments: {currencyFormat(subtotal(overallPayments))}</div>
                )}
                &nbsp;&nbsp;
                {(user.is_payment_accessible !== 'false') && (
                    <div className="text_balance">Balance: {currencyFormat(balance(reports, overallPayments))}</div>
                )}
            </header>

            <div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 600 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">#</StyledTableCell>
                                <StyledTableCell align="left">Project</StyledTableCell>
                                <StyledTableCell align="left">Status</StyledTableCell>
                                <StyledTableCell align="right">Work Done Value</StyledTableCell>
                                <StyledTableCell align="right">GST 13%</StyledTableCell>
                                <StyledTableCell align="right">Total</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {reports.map(report => (
                                <StyledTableRow key={report._id}>
                                    <StyledTableCell align="left">{report.seq}</StyledTableCell>
                                    <StyledTableCell align="left">{report.account}</StyledTableCell>
                                    <StyledTableCell align="left">{report.status}</StyledTableCell>
                                    <StyledTableCell align="right">{currencyFormat(report.work_value)}</StyledTableCell>
                                    <StyledTableCell align="right">{currencyFormat(report.gst)}</StyledTableCell>
                                    <StyledTableCell align="right">{currencyFormat(report.amount)}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Button variant="outlined" size="small" onClick={() => handleOnClickView(report.account)}>View</Button>
                                        &nbsp;&nbsp;
                                        {(user.is_payment_accessible !== 'false') && (
                                            <Button variant="outlined" size="small" onClick={() => showPaymentsDetailPopup(report.account, report.amount)}>Payments</Button>
                                        )}
                                    </StyledTableCell>
                                    <PaymentsDetailPopup
                                        isVisible={isPaymentPopupVisible}
                                        onClose={hidePaymentPopup}
                                        project={selectedProject}
                                        projectTotal={selectedProjectTotalAmount} />
                                </StyledTableRow>
                            ))};
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        </div >
    );
};

export default Summary;
