import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import PurchaseDetailPopup from './PurchaseDetailPopup';
import { useNavigate } from 'react-router-dom';
import { currencyFormat, StyledTableRow, StyledTableCell, getRoundOffValue } from './Comman';

function totalWithGST(items) {
    return ((items.reduce((total, item) => total + parseFloat(item.amount) || 0, 0)) * 1.1);
}

function getQuantity(amount, unit_price) {
    if (unit_price > 0) {
        return getRoundOffValue(amount / unit_price);
    }
    return '';
}

const ProjectSummary = () => {
    const navigate = useNavigate();

    const [materialsSummary, setMaterialsSummary] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const location = useLocation();
    const { account } = location.state || {};

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const hidePopup = () => setIsPopupVisible(false);

    const showPurchaseDetailPopup = (subcategory_val) => {
        setSelectedMaterial(subcategory_val);
        setIsPopupVisible(true);
    }

    const handleBackClick = () => {
        navigate(-1); // go back to the previous page
    };

    useEffect(() => {
        const fetchMaterialsSummary = async () => {
            setIsLoading(true);
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + `/api/expensemanager/expense_report_summary`, { account });
                await setMaterialsSummary(response.data);
            } catch (error) {
                console.error('Error fetching category data:', error);
                setError('Error fetching material summary data!')
            }
        };
        fetchMaterialsSummary();
        setIsLoading(false)
    }, []);

    // Render loading state, error, or data
    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            <header className="header">
                <h1 className="heading">Summary / {account}</h1>
                <Button variant="outlined" size="small" onClick={handleBackClick}>Back</Button>
                &nbsp;&nbsp;
                <div className="text_total">Total: {currencyFormat(totalWithGST(materialsSummary))}</div>
            </header>
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 600 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">#</StyledTableCell>
                                <StyledTableCell align="left">Materials</StyledTableCell>
                                <StyledTableCell align="left">Unit</StyledTableCell>
                                <StyledTableCell align="right">Quantity</StyledTableCell>
                                <StyledTableCell align="right">Average Cost</StyledTableCell>
                                <StyledTableCell align="right">Amount</StyledTableCell>
                                <StyledTableCell align="right">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {materialsSummary.map((materialSummary) => (
                                <StyledTableRow key={materialSummary.seq}>
                                    <StyledTableCell align="left">{materialSummary.seq}</StyledTableCell>
                                    <StyledTableCell align="left">{materialSummary.material}</StyledTableCell>
                                    <StyledTableCell align="left">{materialSummary.unit}</StyledTableCell>
                                    <StyledTableCell align="right">{getQuantity(materialSummary.amount, materialSummary.unit_price)}</StyledTableCell>
                                    <StyledTableCell align="right">{currencyFormat(materialSummary.unit_price)}</StyledTableCell>
                                    <StyledTableCell align="right">{currencyFormat(materialSummary.amount)}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Button variant="outlined" size="small" onClick={() => showPurchaseDetailPopup(materialSummary.material)}>Details</Button>
                                    </StyledTableCell>
                                    <PurchaseDetailPopup
                                        isVisible={isPopupVisible}
                                        onClose={hidePopup}
                                        content={selectedMaterial} />

                                </StyledTableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}



export default ProjectSummary;
