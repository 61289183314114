import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Modal from 'react-modal';
import { currencyFormat, getTowDecimalValue, StyledTableRow, StyledTableCell, isNotEmpty } from './Comman';

//Modal.setAppElement('#root');

function total(items) {
    return items.reduce((total, item) => total + parseFloat(item.amount) || 0, 0);
}

const getUpdatedReceiptUrl = (imageUrl) => {
    if (isNotEmpty(imageUrl)) {
        const parts = imageUrl.split('/');
        const result = process.env.REACT_APP_IMAGE_URL + '/receipt/' + parts[parts.length - 1];
        return encodeURI(result);
    }
    return imageUrl;
};

const PurchaseDetailPopup = ({ isVisible, onClose, content }) => {
    const [isReceiptViewModalOpen, setIsReceiptViewModalOpen] = useState(false);
    const [receiptUrl, setReceiptUrl] = useState('');
    const openReceiptViewModal = (receiptUrl) => {
        setReceiptUrl(getUpdatedReceiptUrl(receiptUrl));
        setIsReceiptViewModalOpen(true);
    };

    const [expensesReport, setExpensesReport] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const location = useLocation();
    const { account } = location.state || {};

    useEffect(() => {
        const fetchExpenseReport = async () => {
            setIsLoading(true);
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + `/api/expensemanager/expense_report`, { account, content });
                await setExpensesReport(response.data);
            } catch (error) {
                console.error('Error fetching category data:', error);
                setError('Error fetching expense report data!')
            }
        };
        fetchExpenseReport();
        setIsLoading(false)
    }, [isVisible]);

    if (!isVisible) return null;

    // Render loading state, error, or data
    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return ReactDOM.createPortal(
        <div className="popup">
            <div className="popup-content">
                <span className="close" onClick={onClose}>&times;</span>
                <header className="header">
                    <h2>{account} / {content}</h2>
                    &nbsp;&nbsp;
                    <div className="text_total">Total: {currencyFormat(total(expensesReport))}</div>
                </header>

                <div className="scrollable-content">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 600 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">#</StyledTableCell>
                                    <StyledTableCell align="left">Date</StyledTableCell>
                                    <StyledTableCell align="left">Bill No</StyledTableCell>
                                    <StyledTableCell align="left">Vendor</StyledTableCell>
                                    <StyledTableCell align="left">Material</StyledTableCell>
                                    <StyledTableCell align="left">Unit</StyledTableCell>
                                    <StyledTableCell align="left">Quantity</StyledTableCell>
                                    <StyledTableCell align="right">Unit Price</StyledTableCell>
                                    <StyledTableCell align="right">Amount</StyledTableCell>
                                    <StyledTableCell align="right">Bill Images</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {expensesReport.map((expenseReport, index) => (
                                    <StyledTableRow key={expenseReport.seq}>
                                        <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                        <StyledTableCell align="left">{expenseReport.expensed_date}</StyledTableCell>
                                        <StyledTableCell align="left">{expenseReport.bill_no}</StyledTableCell>
                                        <StyledTableCell align="left">{expenseReport.vendor}</StyledTableCell>
                                        <StyledTableCell align="left">{expenseReport.material}</StyledTableCell>
                                        <StyledTableCell align="left">{expenseReport.unit}</StyledTableCell>
                                        <StyledTableCell align="left">{getTowDecimalValue(expenseReport.quantity)}</StyledTableCell>
                                        <StyledTableCell align="right">{currencyFormat(expenseReport.unit_price)}</StyledTableCell>
                                        <StyledTableCell align="right">{currencyFormat(expenseReport.amount)}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            {isNotEmpty(expenseReport.receiptImageUrl) && (
                                                <Button variant="outlined" size="small" onClick={() => openReceiptViewModal(expenseReport.receiptImageUrl)}>View Bill</Button>
                                            )}

                                            <Modal
                                                isOpen={isReceiptViewModalOpen}
                                                onRequestClose={() => setIsReceiptViewModalOpen(false)}
                                                contentLabel="Receipt"
                                                style={{
                                                    content: {
                                                        top: '50%',
                                                        left: '50%',
                                                        right: 'auto',
                                                        bottom: 'auto',
                                                        marginRight: '-50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        padding: '20px',
                                                    },
                                                }}>
                                                <button
                                                    onClick={() => setIsReceiptViewModalOpen(false)}
                                                    style={{ float: 'right', background: 'none', border: 'none', fontSize: '1.5em' }}>
                                                    &times;
                                                </button>
                                                <div style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={receiptUrl}
                                                        alt={receiptUrl}
                                                    />
                                                </div>
                                            </Modal>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>,
        document.body // Append to body
    );
};

export default PurchaseDetailPopup;
