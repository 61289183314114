import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const { login } = useAuth();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            //const res = await axios.get(process.env.REACT_APP_API_URL + '/api/users', {
            const res = await axios.post(process.env.REACT_APP_API_URL + '/api/auth/login', {
                username,
                password
            });
            //console.log(res.data.token); // This should log the JWT token

            // Handle successful login (e.g., store token in local storage)
            const name = res.data.name;
            const project_access = res.data.project_access;
            const is_payment_accessible = res.data.is_payment_accessible;

            login({ username, password, name, project_access, is_payment_accessible });
            localStorage.setItem("token", res.data.token);
            navigate('/account')
        } catch (err) {
            // Handle error (e.g., show error message)
            //console.error(err)
            //console.error(err.response.data.message);
            setError("err.response.data.message")
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <br /><br /><br />
            <h3>Login Page</h3>
            <br />
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
                &nbsp;&nbsp;&nbsp;
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                &nbsp;&nbsp;&nbsp;
                <button type="submit">Login</button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div >

    );
};

export default Login;
